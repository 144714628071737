<template>
  <v-app>
    Cheching Auth.......
  </v-app>
</template>

<script>
// import { mapActions } from "vuex";
import { decrypt } from '../services/IncryptionService'
export default {
  name: 'Authguard',
  mounted() {
        if (localStorage.getItem("auth_token")) {
          let normalSrring=decrypt(localStorage.getItem("auth_token"));
          // console.log("=======>", normalSrring);
          if(normalSrring) {
            let loginData = {
              email: normalSrring.split("-")[0],
              password: normalSrring.split("-")[1]
            }
            if (loginData.email && loginData.password) {
              this.$store.dispatch('loginUser', loginData)
            }
          }
        } else {
          this.$router.push('/login');
        }
    }
}
</script>

