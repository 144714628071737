<template>
  <v-app>
    <v-card class="ds-card custom-ds-card">
      <v-row>
        <v-col class="log-section1">
            <h1>Your personal equity manager. <span>We make every trade safer and smarter.</span></h1>
            <div class="ls-para1">
                <p>
                  Perquity is an intelligent aide that tracks your equity investments. It provides you with timely advice to manage your portfolio’s health and achieve your financial dreams. 
                </p>
                <p>
                Whether you are just starting to invest or have been investing for years, Perquity guides you at every stage of your journey. 
                </p>
            </div>
            <p class="lets_start">
              Lets get started.
            </p>
        </v-col>
        <v-divider vertical class="ds-divider"></v-divider>
        <v-col
          ><v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="model.name"
              :counter="10"
              :rules="rules.name"
              label="Name"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="model.email"
              :rules="rules.email"
              label="E-mail"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="model.mobile"
              :rules="rules.mobile"
              label="Mobile Phone"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="model.dmat"
              :rules="rules.dmat"
              label="DEMAT account"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="model.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              :rules="rules.password"
              label="Password"
              counter
              @click:append="show = !show"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              v-model="model.confirmPassword"
              :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirm ? 'text' : 'password'"
              :rules="[
                model.password === model.confirmPassword ||
                  'Passwords must match'
              ]"
              label="Confirm Password"
              counter
              @click:append="showConfirm = !showConfirm"
              required
              autocomplete="off"
            ></v-text-field>
            <div class="signIn-wrap">
              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4 ds-btn"
                @click="validate"
              >
                SIGN UP
              </v-btn>
            </div>
          </v-form></v-col
        >
      </v-row>
    </v-card>

  <v-card>
      <v-row>
        <v-col>
          <div class="custom-log-form-bottom">
            Perquity is built on the golden advice of Warren Buffet - “RULE NO. 1 IS NEVER LOSE MONEY. RULE NO. 2 IS NEVER FORGET RULE NO. 1”
          </div>
          <div class="custom-col-grid-three">
               <div class="col-box">
                  <h1>
                    Make informed decisions on strengthening your portfolio
                  </h1>
                  <div class="col-box-p">
                      <p>
                        The equities market takes time to learn and understand. The right time to buy and sell is crucial to growth. 
                      </p>
                      <p>
                        Our unique algorithm provides trade advice based on the performance of your portfolio. It will identify shares to trade in and let you know at which price you can receive the greatest returns.                        
                      </p>
                  </div>
              </div>
              <div class="col-box">
                  <h1>
                    Ambition is the only limitation to meet your investment goals.
                  </h1>
                  <div class="col-box-p">
                      <p>Historical performance, current market trends, eco-political factors - all these things could affect your portfolio. 
                      </p>
                      <p>
We track all the details so that you just need to make the big decisions. No matter what your investment or risk appetite, Perquity provides the perfect advice to ensure you always meet your targets.              
                      </p>
                  </div>
              </div>              
              <div class="col-box">
                  <h1>
                    Learn and grow along with your portfolio.
                  </h1>
                  <div class="col-box-p">
                      <p>
Irrespective of whether you are a novice, expert or anywhere in between, our insights will always cater to your specific needs. 
                      </p>
                      <p>
                        
Perquity will guide you with the relevant advice to strengthen and grow your portfolio at every step of your journey. Our suggestions will give you the confidence to make your own investment decisions.
              
                      </p>
                  </div>
              </div>                                                     
          </div>
        </v-col>
      </v-row>    
  </v-card>



  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Register',
  data: () => ({
    show: false,
    showConfirm: false,
    valid: false,
    model: {
      name: '',
      email: '',
      mobile: '',
      dmat: '',
      password: '',
      confirmPassword: ''
    },
    rules: {
      name: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 64) || 'Name must be less than 64 characters'
      ],
      email: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      mobile: [
        (v) => !!v || 'Mobile phone number is required'
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      dmat: [
        // v => !!v || "E-mail is required"
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      password: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 6) || 'Password must be more than 6 characters'
      ]
    }
  }),

  methods: {
    validate() {
      this.$refs.form.validate() ? this.createUser(this.model) : false
    },
    // registerUser() {
    //   // let registerUser = {
    //   //   name: this.name,
    //   //   email: this.email,
    //   //   password: this.password
    //   // };
    //   this.$store.dispatch("createUser", this.model);
    //   // console.log(this.model);
    // },
    ...mapActions(['createUser'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.ds-card {
  padding: 5.375rem;
  margin: 5.375rem;
}
.ds-btn {
  background-color: $colors-brand-primary !important;
}
h1 {
  @include display_large_medium;
  margin-bottom: 2rem;
}
h2 {
  @include heading_2_regular;
  color: $colors-brand-primary;
  line-height: 2rem;
  margin-bottom: 2rem;
}
p {
  @include paragraph_3_medium;
  line-height: 1.2rem;
}
.ds-divider {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}


.custom-ds-card
{
  background-color: #7557CC;
  font-size:36px;
}

.log-section1 h1 {
    font-size:30px;
    line-height: 46px;
    color: #6CD6C4;
    font-weight: 700;
}

.log-section1 span {
    font-weight: 300;
    
}

.ls-para1 p 
{
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.lets_start
{
    
    color: #67D6C4;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    margin-top:40px;
}


.signIn-wrap
{
  margin-top: 40px;
}
.signIn-wrap button.ds-btn
{
  background-color: #67D6C4 !important;
}

.ds-divider
{
  background-color: #fff;
}

.signIn-wrap button span {
    color: #7557CC;
    font-size:16px;
    line-height:24px;
    font-weight:500;
}

.theme--light .signIn-wrap .v-btn.v-btn--disabled.v-btn--has-bg
{
  background-color: #67D6C4 !important;
}

.theme--light .signIn-wrap .v-btn span
{
  color: #7557CC;
}

.v-v-text-field__slot
{
    border: unset !important;
    border-bottom: 1px solid red !important;
}


.custom-log-form-bottom 
{
    padding: 30px 30px;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    color: #7557CC;
    width: 60%;
    margin: 0 auto;
}

.custom-col-grid-three 
{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
    padding: 0 50px;
    margin-bottom:40px;
    width:75%;
    margin:0 auto 40px;
}

.custom-col-grid-three .col-box {
    padding: 25px;
    background: #7557CC;
    box-sizing: border-box;
}

.custom-col-grid-three .col-box h1
{
    color:#67D6C4;
    font-size:16px;
    line-height:25px;
    font-weight:700;
    margin-bottom: 20px;
}

.custom-col-grid-three .col-box p
{
    color:#fff;
}
</style>
